import { memo, useMemo } from 'react';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import { useFetchRetailers } from '../../../../api/automator/retailers/useFetchRetailers';
import { colors, Tooltip } from '@mui/material';

interface RetailerLooksIconProps {
  retailerId: number;
}

const RetailerLooksIcon = memo(({ retailerId }: RetailerLooksIconProps) => {
  const { data: retailers, isLoading } = useFetchRetailers();

  const retailer = useMemo(() => {
    if (isLoading || !retailers) return null;
    return retailers.find((retailer) => retailer.id === retailerId);
  }, [retailers, retailerId, isLoading]);

  const index = retailer ? (retailers?.indexOf(retailer) ?? 0) + 1 : null;

  const determineIcon = useMemo(() => {
    const style = {
      backgroundColor: 'WHITE',
      color: colors.lightBlue[500],
    };

    switch (index) {
      case 1:
        return (
          <LooksOneIcon
            style={style}
            fontSize={'small'}
          />
        );
      case 2:
        return (
          <LooksTwoIcon
            style={style}
            fontSize={'small'}
          />
        );
      case 3:
        return (
          <Looks3Icon
            style={style}
            fontSize={'small'}
          />
        );
      case 4:
        return (
          <Looks4Icon
            style={style}
            fontSize={'small'}
          />
        );
      case 5:
        return (
          <Looks5Icon
            style={style}
            fontSize={'small'}
          />
        );
      case 6:
        return (
          <Looks6Icon
            style={style}
            fontSize={'small'}
          />
        );
      default:
        return (
          <DeviceUnknownIcon
            style={style}
            fontSize={'small'}
          />
        );
    }
  }, [index]);

  if (!index || !retailer) return null;

  return (
    <Tooltip
      enterDelay={500}
      enterNextDelay={500}
      title={retailer?.name}
      placement={'right'}
    >
      {determineIcon}
    </Tooltip>
  );
});

RetailerLooksIcon.displayName = 'RetailerLooksIcon';

export default RetailerLooksIcon;
