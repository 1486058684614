import Badge from '@mui/material/Badge';

interface OpenReimbursementsBadgeProps {
  amountOfOpenReimbursements: number;
}

export const OpenReimbursementsBadge = ({
  amountOfOpenReimbursements,
}: OpenReimbursementsBadgeProps) => {
  if (amountOfOpenReimbursements === 0) {
    return '';
  }

  return (
    <Badge
      badgeContent={'+' + amountOfOpenReimbursements}
      color="primary"
    ></Badge>
  );
};
