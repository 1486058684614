import { TableTabs, TabOption } from '../../../../components/TableTabs';

interface OrderSummaryTabsProps {
  currentTab: OrderSummaryTab;
  setCurrentTab: (currentTab: OrderSummaryTab) => void;
}

export enum OrderSummaryTab {
  ORDER_EVENTS = 'ORDER_EVENTS',
  MESSAGE_THREADS = 'MESSAGE_THREADS',
}

const OrderSummaryTabs = ({ currentTab, setCurrentTab }: OrderSummaryTabsProps) => {
  const tabOptions: TabOption<OrderSummaryTab>[] = [
    {
      label: OrderSummaryTab.ORDER_EVENTS,
      value: OrderSummaryTab.ORDER_EVENTS,
    },
    {
      label: OrderSummaryTab.MESSAGE_THREADS,
      value: OrderSummaryTab.MESSAGE_THREADS,
    },
  ];

  return (
    <TableTabs
      value={currentTab}
      onChange={(tab: OrderSummaryTab) => {
        setCurrentTab(tab);
      }}
      tabOptions={tabOptions}
    />
  );
};

export default OrderSummaryTabs;
