import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { SETTINGS_PATH } from '../automatorApiPaths';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Account from '../../../domain/automator/account/Account';

export const useUpdateSettings = (settingsId: number) => {
  const url = useAutomatorApiAccountUrl(`${SETTINGS_PATH}/${settingsId}`);
  return useAuthorizedPost<Account, UpdateSettingsData>(url, [EndpointQueryKey.ACCOUNT]);
};

export interface UpdateSettingsData {
  automatically_approve_cancellation_requests: boolean;
  ignore_latest_handover_date: boolean;
  forward_auto_mail_replies: boolean;
}
