import Badge from '@mui/material/Badge';

interface OpenResendsBadgeProps {
  amountOfOpenResends: number;
}

export const OpenResendsBadge = ({ amountOfOpenResends }: OpenResendsBadgeProps) => {
  if (amountOfOpenResends === 0) {
    return '';
  }

  return (
    <Badge
      badgeContent={'+' + amountOfOpenResends}
      color="primary"
    ></Badge>
  );
};
