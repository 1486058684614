import { tss } from 'tss-react/mui-compat';
import Box from '@mui/material/Box';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';

interface FlagIconProps {
  countryCode: CountryCode;
  onClick?: (country: CountryCode) => void;
  size?: number;
  isActive?: boolean;
}

export const FlagIcon = ({ countryCode, onClick, size, isActive }: FlagIconProps) => {
  const { cx, classes } = useStyles();

  const determineSvg = (country: CountryCode) => {
    switch (country) {
      case CountryCode.BE:
        return 'flag-be.svg';
      case CountryCode.NL:
        return 'flag-nl.svg';
    }
  };

  const asize = size ? size : 16;

  return (
    <Box
      sx={{ cursor: onClick ? 'pointer' : 'default' }}
      onClick={() => onClick?.(countryCode)}
      width={asize + (isActive ? 20 : 0)}
    >
      <img
        className={cx(classes.flagImage)}
        src={'/assets/flags/' + determineSvg(countryCode)}
        alt={''}
      />
    </Box>
  );
};

const useStyles = tss.create(() => ({
  flagImage: {
    width: '100%',
  },
}));
