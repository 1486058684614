import Stack from '@mui/material/Stack';
import { OrderSummaryOrderCardItem } from './OrderSummaryOrderCardItem';
import { OrderSummaryShipmentCardItem } from './OrderSummaryShipmentCardItem';
import { useSearchOrder } from '../../../../api/automator/orders/useSearchOrder';
import { OrderSummaryOrderNoteCardItem } from './OrderSummaryOrderNoteCardItem';
import OrderSummaryTabs, { OrderSummaryTab } from './OrderSummaryTabs';
import { useState } from 'react';
import { OrderSummaryOrderEvents } from './OrderSummaryOrderEvents';
import { OrderSummaryMessageThreads } from './OrderSummaryMessageThreads';
import { OrderSummaryCard } from './OrderSummaryCard';
import { OrderSummaryRecurringOrdersCardItem } from './OrderSummaryRecurringOrdersCardItem';
import { OrderSummaryReturnsCard } from './OrderSummaryReturnsCard';

interface OrderSummaryProps {
  query: string;
  onClose?: () => void;
}

export const OrderSummary = ({ query: initialQuery, onClose }: OrderSummaryProps) => {
  const { data: order, isLoading } = useSearchOrder(initialQuery);

  const [currentTab, setCurrentTab] = useState(OrderSummaryTab.ORDER_EVENTS);

  if (isLoading) {
    return <>...loading</>;
  }

  if (!order) {
    onClose?.();
  }

  return (
    <Stack
      gap={2.5}
      direction="column"
    >
      <Stack
        direction="row"
        gap={2}
      >
        <Stack
          direction="column"
          gap={2}
        >
          <OrderSummaryCard>
            <OrderSummaryOrderCardItem order={order!} />
            <OrderSummaryShipmentCardItem orderId={order!.id} />
            <OrderSummaryRecurringOrdersCardItem
              recurringBolOrderIds={order!.repeatCustomerBolOrderIds}
              placedDoubleBolOrderIds={order!.doublePlacedBolOrderIds}
            />
          </OrderSummaryCard>

          <OrderSummaryReturnsCard orderId={order!.id} />

          <OrderSummaryCard>
            <OrderSummaryOrderNoteCardItem orderId={order!.id} />
          </OrderSummaryCard>
        </Stack>

        <Stack
          direction="column"
          gap={1}
          marginTop={-2}
        >
          <OrderSummaryTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {currentTab === OrderSummaryTab.ORDER_EVENTS && (
            <OrderSummaryOrderEvents orderId={order!.id} />
          )}
          {currentTab === OrderSummaryTab.MESSAGE_THREADS && (
            <OrderSummaryMessageThreads orderId={order!.id} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
