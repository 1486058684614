import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Retailer from '../../../../domain/automator/retailers/Retailer';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { useUpdateRetailerAddress } from '../../../../api/automator/retailers/useUpdateRetailerAddress';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import Address from '../../../../domain/automator/orders/Address';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import Alert from '@mui/material/Alert';

interface UpdateRetailerAddressFormProps {
  retailer: Retailer;
  onSave: () => void;
}

interface FormInputs {
  zipCode: string;
  streetName: string;
  houseNumber: string;
  houseNumberExtension: string | null;
  city: string;
  countryCode: CountryCode;
}

export const UpdateRetailerAddressForm = ({ retailer, onSave }: UpdateRetailerAddressFormProps) => {
  const { t } = useTranslation();

  const { mutate: updateRetailerAddress, isLoading } = useUpdateRetailerAddress(retailer.id);

  const zipNLRegex = new RegExp('^\\d{4}[a-zA-Z]{2}$');
  const zipBERegex = new RegExp('^\\d{4}$');

  const doUpdate = (data: Address) => {
    updateRetailerAddress(
      { address: data },
      {
        onSuccess: async () => {
          toast.success(t(tokens.automator.settings.settings_saved));
          onSave();
        },
      }
    );
  };

  const onSubmit = (data: FormInputs) => {
    data.zipCode = data.zipCode.trim().toUpperCase();
    data.streetName = data.streetName.trim();
    data.houseNumber = data.houseNumber.trim();
    if (data.houseNumberExtension) {
      data.houseNumberExtension = data.houseNumberExtension.trim();
    }
    data.city = data.city.trim();

    if (zipNLRegex.test(data.zipCode)) {
      data.countryCode = CountryCode.NL;
    } else if (zipBERegex.test(data.zipCode)) {
      data.countryCode = CountryCode.BE;
    }

    doUpdate(data);
  };

  const validationSchema = Yup.object({
    zipCode: Yup.string()
      .required(t(tokens.common.required) as string)
      .matches(/^(\d{4}[a-zA-Z]{2}|\d{4})$/, t(tokens.common.incorrect) as string),
    streetName: Yup.string().required(t(tokens.common.required) as string),
    houseNumber: Yup.string()
      .required(t(tokens.common.required) as string)
      .matches(/^\d+$/, t(tokens.common.incorrect) as string),
    houseNumberExtension: Yup.string().nullable().defined().default(null),
    city: Yup.string().required(t(tokens.common.required) as string),
    countryCode: Yup.mixed()
      .oneOf([CountryCode.NL, CountryCode.BE], t(tokens.common.incorrect) as string)
      .required(t(tokens.common.required) as string),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: retailer.address || ({} as FormInputs),
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3}>
        <Alert severity="info">
          {t(tokens.automator.retailers.update_retailer_address.update_retailer_address_info)}
        </Alert>

        <Stack
          direction="row"
          gap={1}
        >
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.common.address.city)}
                error={Boolean(errors.city)}
                helperText={errors.city?.message}
              />
            )}
          />
          <Controller
            name="zipCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.common.address.zip_code)}
                error={Boolean(errors.zipCode)}
                helperText={errors.zipCode?.message}
                onChange={(event) => field.onChange(event.target.value.trim())}
              />
            )}
          />
        </Stack>

        <Stack
          direction={'row'}
          gap={1}
        >
          <Controller
            name="streetName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.common.address.street_name)}
                error={Boolean(errors.streetName)}
                helperText={errors.streetName?.message}
              />
            )}
          />
          <Controller
            name="houseNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.common.address.house_number)}
                error={Boolean(errors.houseNumber)}
                helperText={errors.houseNumber?.message}
                onChange={(event) => field.onChange(event.target.value.trim())}
              />
            )}
          />
          <Controller
            name="houseNumberExtension"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.common.address.house_number_extension)}
                error={Boolean(errors.houseNumberExtension)}
                helperText={errors.houseNumberExtension?.message}
              />
            )}
          />
        </Stack>

        <ActionButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          label={t(tokens.automator.retailers.update_retailer_address.update_retailer_address)}
          isLoading={isLoading}
        />
      </Stack>
    </form>
  );
};
