import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

const CustomerOrderPage = lazy(() => import('src/pages/customer/pages/CustomerOrderPage'));

export const customerRoutes: RouteObject[] = [
  {
    path: 'customers',
    element: (
      <Suspense>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'orders/:customerToken',
        element: <CustomerOrderPage />,
      },
    ],
  },
];
